import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'simpo-component-library';
import { HomeService } from './home.service';
import LocalStorage from '../state/local-storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class NavbarRedirectionsService implements OnDestroy {
  constructor(private _router: Router, private localStoarge: LocalStorage,
    private readonly loadingService: NgxUiLoaderService,
  ) {}

  pageChanged = new EventEmitter<any>();

  instanceSubscription : Array<any> =[]
  pageRedirection(instance: any) {
    this.instanceSubscription.push(
      instance
    )
    if (instance?.redirectionUrlV2?.includes("terms-and-condition") || instance?.redirectionUrlV2?.includes("privacy") || instance?.redirectionUrlV2?.includes("faq")) {
      
      this.localStoarge.set('pId', instance.pageId);
      this._router.navigate([`${instance.redirectionUrlV2}`]);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
    else if(instance.redirectionUrl.split("_").includes("/New")) {
      if(window.location.href.includes("/New")){
        this.loadingService.start("scroll")
        this._router.navigate([`/${instance.redirectionUrl}`]);
        this.localStoarge.set('pId', instance.pageId);
      }
      else{
        this._router.navigate([`/${instance.redirectionUrl}`]);
        this.localStoarge.set('pId', instance.pageId);
      }
      setTimeout(() => {
        window.location.reload();
      }, 100);
      // this.pageChanged.emit({data:true});
    } else if (instance.redirectionUrl === '' || instance.redirectionUrl === '/') {
      this._router.navigate(['']);
    } 
    else {
      this.localStoarge.set('pId', instance.pageId);
      this._router.navigate([`/blog`]);
    }
  }

  ngOnDestroy(): void {
    this.instanceSubscription.forEach(element => {
      element.unsubscribe();
    });
  }

}
