<router-outlet></router-outlet>

<!-- <div class="showbag" (click)="goToCart()" *ngIf="showBag">
    <img src="https://i.postimg.cc/jj5Xh7Ry/shopping-bag.png" alt="">
</div> -->
<div class="voice-assistance" *ngIf="isEcommerceWebsite">
    <div class="trans-text trim-text" [style.width.px]="speechText.length > 0 ? 250 : 0" [style.justifyContent]="isSpeaking ? 'center' : ''"></div>
    <img src="assets/images/mic.gif" alt="" (click)="toggleSpeech()" *ngIf="isSpeaking" class="mic-on">
    <img src="assets/images/mic-off.png" alt="" (click)="toggleSpeech()" style="height: 30px; width: 30px;" *ngIf="!isSpeaking">
</div>
<div class="speaking-overlay" *ngIf="showSpeakingOverlay">
    <mat-icon (click)="showSpeakingOverlay = false">close</mat-icon>
    <img src="assets/images/mic.gif" alt="" (click)="toggleSpeech()" *ngIf="isSpeaking" style="height: 75px; width: 75px; left: 42%; bottom: 8px">
    <img src="assets/images/mic-off.png" alt="" (click)="toggleSpeech()" *ngIf="!isSpeaking">
</div>

<mat-icon class="add-to-homescreen" (click)="overlayPreview = true" *ngIf="!isPWAInstalled && isEcommerceWebsite">add_to_home_screen</mat-icon>
<div class="add-home-overlay" *ngIf="overlayPreview">
    <div class="position-relative">
        <div class="bold-text mb-10">Enjoy app-like experience</div>
        <ul>
            <li>It's SuperFast</li>
            <li>Easy To Access</li>
            <li>Saves Space</li>
        </ul>
        <div class="position-relative" style="margin-top: 20px;">
            <div class="bold-text mb-10">How to add to {{ 'Business Name' }} your homescreen?</div>
            <img src="https://constant.myntassets.com/pwa/assets/img/edc1d35b-43a5-4a5b-ad1d-8b477746caaa1549896768222-arrow-up-2x.png" alt="" class="arrow-img">
        </div>
        <ul class="steps">
            <li>Tap ":" icon on top right of the screen.</li>
            <li>Select "Add to Home screen" from the menu.</li>
        </ul>
        <button (click)="overlayPreview = false">Okay! Got It</button>
    </div>
</div>

